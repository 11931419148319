.img_side{
    height: 100px;
    margin: 0 auto 10px;
    display: block;
}

.fb, .ig {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fb-page, .fb_1, .fb_2 {
    display: block !important;
}

.fb, .ig {
    margin-bottom: 10px;
}

.pagination .page-item.active .page-link {
    background-color: #2CD845;
    border-color: #2CD845;
    color: #fff;
}

.pagination .page-link {
    background-color: #ffffff;
    border-color: #2CD845;
    color: #000;
}

.pagination .page-link:hover {
    background-color: #ffffff;
    border-color: #2CD845;
    color: #2CD845;
}

.details-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.details-title {
    font-size: 2.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: left;
}

.details-date {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.details-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 30px;
}

.details-files {
    margin-top: 30px;
}

.details-files h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2CD845;
    margin-bottom: 15px;
}

.file-link a {
    display: block;
    font-size: 1.1rem;
    color: #007bff;
    text-decoration: none;
    margin-bottom: 10px;
}

.file-link a:hover {
    text-decoration: underline;
}

.details-videos {
    margin-top: 40px;
}

.details-videos h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2CD845;
    margin-bottom: 15px;
}

.video-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.btn-back {
    display: inline-block;
    margin-top: 30px;
    background-color: #2CD845;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.btn-back:hover {
    background-color: #28b43b;
}

