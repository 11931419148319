.newsletter{
    background-color: white;
    border-radius: 15px;
    padding: 10px;
}

.container {
    display: flex;
    align-items: center;
}

.newsletter-logo {
    margin-right: 20px;
}

.newsletter-input {
    align-items: center;
}

.newsletter-text {
    flex: 1;
}

.newsletter-paragraph {
    margin-top: 0;
    margin-bottom: 10px;
}

.input_newsletter {
    margin-right: 10px;
}

.input_email{
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    border: white 0;
    height: 35px;
    width: 30%;
}

.btn_odebirat{
    background-color: white;
    color: black;
    height: 100%;

}

.close-container {
    background-color: white;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    margin-left: auto;
    width: 100%
}

.close-icon{
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
}

.arrow_back{
    display: inline-block;
    background-color: #2CD845;
    color : white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.newsletter_container{
    position: fixed;
    bottom: 0;
}


