.line{
    border-top: 1.5px solid #2CD845;
}

/* pracovni listy */
.card {
    width: 100%;
    max-width: 450px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px -8px black;
    margin-bottom: 20px;
    transition: all 0.3s ease;
    overflow: hidden;
}
.card:hover {
    background-color: hsl(129, 69%, 92%);
}

.card-img-top{
    border-radius: 10px 10px 0 0;
    transition: all 0.3s ease;
    border-bottom: 3px solid hsl(129, 69%, 80%);
}

.card-img-top:hover{
transform: scale(1.05);
}

.row{
    width: 80%;
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 768px) 
{
    .row {
        width: 90%;
    }
}

.green-line {
    color: #2CD845;
    background-color: #2CD845;
    height: 2px;
    border: none;
}

.dark-line {
    color: darkgreen;
    background-color: darkgreen;
    height: 2px;
    border: none;
    padding: 0;
    margin: 0;
}

.images_ {
    margin-bottom: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.fb,
.ig {
    width: 100%;
    max-width: 280px;
    height: 500px;
    margin-bottom: 20px;
    margin-left: 35px;
}




#all_mapa {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    overflow: hidden;
}

#main_mapa {
    width: 1000px;
    height: 420px;
    margin-left: auto;
    margin-right: auto;
    text-align: right;

}

#main_mapa_en{
    width: 1000px;
    height: 420px;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
}

.carousel-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -999;
    overflow: hidden;
}


.obrazek_mapa {
    align-self: center;
    max-width: 100%;
    max-height: 100%;
    height: auto;
}


.carousel-control-prev,
.carousel-control-next {
    display: none;
}

.nadpis_padding{
    padding-top: 30px;
    margin-bottom: -10px;
}

.card_{
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px -8px black;
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    border: 1px solid rgba(0, 0, 0, 0.190);
}

.card-body{
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}

/* obsah-sekce aktualne */
.card- {
    border-radius: 10px;
    box-shadow: 0px 5px 10px -8px black;
    border: 1px solid rgba(0, 0, 0, 0.190);
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 30px;
    flex: 1 1 calc(50% - 30px);
    margin-left: 10px;
    margin-right: 10px;
    transition: all 0.3s ease;
    overflow: hidden;
}

.card-:hover {
    background-color: hsl(129, 69%, 92%);
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 20px;
}

.btn-akt {
    max-width: 250px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 5px -3px black;
    margin: 0 auto 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #2CD845;
    color: black;
}

.btn-akt:hover{
    background-color: #27b63d;
}

@media only screen and (max-width: 768px) {
    .obrazek_mapa{
        display: none;
    }
}

.aktualne-label {
    color: rgb(110, 110, 110);
    font-size: medium;
}

