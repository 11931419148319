.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #2CD845;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0px 1px 5px -3px black;
    transition: all 0.3s ease;
}

.image-container:hover {
    background-color: #2CD845;
    box-shadow: 0px 0px 5px 0px black;
}

.menu_klikam {
    flex: 1;
    padding-top: 9px;
    padding-bottom: 9px;
    text-align: center;
    font-size: 22px;
}

.menu_klikam h3 {
    margin: 0;
}

.nesmi_uniknout {
    display: flex;
    border: 2px solid #2CD845;
    border-radius: 15px;
    overflow: hidden;
    margin: 10px;
    padding: 10px;
    flex: 1;
    text-align: center;
    width: 100%;
    height: 140px;
}

.picture_vim_kam_klikam{
    height: 50px;
    width: auto;
    padding-left: 20px;
}

.vice-link-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.card-nesmi-uniknout{
    padding : 0;
}

.circle-number-vimkamklikam {
    background-color: #2CD845;
    color: #fff;
    border-radius: 25px 0 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    z-index: 2;
    cursor: pointer;
}

.overlay-vimkamklikam {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20%;
    height: 13%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filtr_div{
    background-color: rgba(44, 216, 69, 0.4);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 15px;
    font-weight: bold;
    color: #333;
    display: inline-block;
    margin-top: 10px;
}

.card-container_ {
    display: flex;
    flex-wrap: wrap;
}


