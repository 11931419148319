.podnadpis{
    padding-bottom: 25px;
}

.card img {
    height: 200px;
    width: 100%;
    object-fit: contain;
    object-position: center;
    padding: 5px;
}

.card_hr{
    margin: 0;
}