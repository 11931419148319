.custom-card {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0px;
}

.plisty-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

@media (max-width: 767px) {
    .plisty-container {
        grid-template-columns: 1fr;
    }
}

.overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20%;
    height: 13%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

}

.circle-number {
    background-color: #2CD845;
    color: #fff;
    border-radius: 25px 0 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    z-index: 2;
    cursor: pointer;
}

.text-center{
    text-align: center;
}

.cover-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2CD845;
    opacity: 0.8;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cover-text{
    color: black;
    opacity: 1;
    font-weight: bold;

}

.box_shadow{
    position: relative;
    overflow: hidden;
}

