.mr-2 {
    margin-right: 0.5rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.flex-grow {
    flex-grow: 1;
}

.form-group{
    margin-bottom: 10px;
}

.input,
textarea {
    border-radius: 25px !important;
    background-color: #E0F9E3 !important;
    border: 0px solid !important;
    padding: 10px;
}

.required {
    color: red;
    margin-left: 5px;
}

.btn-custom{
   background-color: #2CD845;
    color: #2CD845;
}