.my-custom-toolbar {
    background-color: #62eb76; 
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  
  .toolbar-row {
    display: flex;
    justify-content: center;
    margin: 5px 0;
    flex-wrap: wrap;
  }
  
  .navigation-buttons,
  .view-buttons {
    justify-content: space-around; 
  }
  
  .date-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .toolbar-row button,
  .date-label span {
    background-color: #ffffff; 
    color: #000000; 
    margin: 0 5px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    flex: 1; 
    text-align: center;
    border: 1px rgb(94, 94, 94) solid;
  }
  
  .toolbar-row button:hover,
  .date-label span:hover {
    background-color: #91e79e; 
  }
  

  @media (max-width: 600px) {
    .toolbar-row button,
    .date-label span {
      flex-basis: 100%; 
      margin-bottom: 5px;
    }
  }
  

  @media (min-width: 601px) {
    .toolbar-row button,
    .date-label span {
      flex: none; 
      min-width: 80px; 
    }
  }
  
  .rbc-event {
    background-color: #2CD845 !important;
  }
  