.pas 
{
    background: url('./pics/film.png') repeat-x;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.videos 
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
    overflow-x: auto;
}

.videos iframe 
{
    flex: 0 0 auto; 
    width: 170px; 
    height: 95px; 
}

@media (max-width: 768px) 
{
    .videos iframe {
        width: 120px;
        height: 67.5px; 
    }
}

@media (max-width: 480px) 
{
    .videos iframe {
        width: 100px;
        height: 56.25px; 
    }
}
