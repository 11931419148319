.projekt_odkaz{
    text-align: center;
    font-size: 18px;
    padding-top: 10px;
}

.odkaz_projekt{
    color: black;
    text-decoration: none;
}

.img_side{
    width: 65%;
    height: auto;
}

.carousel_ostatni {
    overflow: hidden;
    height: 160px;
}
