.btn_vice{
    background-color: #2CD845;
    color: white;
    border: 0;
    margin-bottom: 12px;
}

.btn-primary:hover{
    background-color: #2CD845;
}

