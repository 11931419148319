.footer {
  background-color: #2CD845;
  color: white;
  text-align: center;
  padding: 10px;
  bottom: 0;
  width: 100%;
  z-index: -999;
}

.footer .container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo {
  margin-right: 20px;
}

.footer-text p {
  margin: 0; 
  text-align: left;
}

@media (max-width: 768px) {
  .footer .container {
    flex-direction: column;
  }
  .footer-logo {
    margin-bottom: 10px;
  }
  .footer-text p {
    text-align: center; 
  }
}
