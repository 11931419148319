.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20%;
    margin-right: 20%;
}

.navbar-brand img {
    max-height: 50px;
}

.navbar-nav {
    margin-left: auto;
}

.menu {
    width: 100%;
}


.nav-item{
    margin: 10px;
}

.btn_jazyk{
    margin-top: -5px;
}

.mobile-only {
    display: none;
}

@media (max-width: 768px) {
    .mobile-only {
        display: block;
    }
}