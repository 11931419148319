.hledat_tlačitko{
    background-color: white;
    border: 0 solid black;
}

.clear-icon{
    background-color: white;
    border: 0 solid black;
}

.search{
    border-radius: 10px;
    border: 1px solid black;
    padding: 2px;
    width: 200px;
}