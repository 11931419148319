
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600&subset=latin,latin-ext');

@import '~typeface-source-sans-pro/index.css';

.card img {
  padding: 0!important;
}

.no-underline {
  text-decoration: none!important;
}

.border-test {
  border: 3px solid red;
}

.navigation-shortcuts a {
  text-decoration: none;
  color: black;
  padding: 5px;
}

.navigation-shortcuts a:hover {
  text-decoration: underline;
  color: darkgreen;
}

.details-date img {
  width: 28px;
  margin-right: 5px;
  margin-bottom: 5px;
}

